input[type="checkbox"] {  
    /* border: 4px solid lighten(#2D3137, 50%); */
    color: #FF7C9B;
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
    appearance: none;
}

input:focus {
    /* border: 2px solid lighten(#FF7C9B, 50%); */
    outline: 2px solid #FF7C9B;
}

input:focus:checked {
    /* border: 2px solid darken(#FF7C9B, 15%); */
    outline: 2px solid #FF7C9B;
}

input:checked {
    color: #FF7C9B;
    background: #FF7C9B url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+) 50% 40% no-repeat;
    /* border: 2px solid #FF7C9B; */
    outline: 2px solid #FF7C9B;
}