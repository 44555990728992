input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input:focus {
    outline: 1px solid #9ed9d8;
}

input::placeholder {
    color: white;
}
