@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: poppins;
  font-weight: 100;
  src: url("../public/poppins/Poppins-Thin.otf") format("opentype");
}

@font-face {
  font-family: poppins;
  font-weight: light;
  src: url("../public/poppins/Poppins-Light.otf") format("opentype");
}

@font-face {
  font-family: poppins;
  src: url("../public/poppins/Poppins-Regular.otf") format("opentype");
}

@font-face {
  font-family: poppins;
  font-weight: bold;
  src: url("../public/poppins/Poppins-Bold.otf") format("opentype");
}

@font-face {
  font-family: poppins;
  font-weight: 350;
  src: url("../public/poppins/Poppins-SemiBold.otf") format("opentype");
}

body {
  margin: 0;
  font-family: poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color:  #EDE7E3;
}

code {
  font-family: poppins, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
